import React from 'react'
import { Box } from '@mui/material'
import { BLOCK_TYPE } from 'pages/Cms/constants'
import {
  CmsContentBlocksT,
  DocumentContentBlockT,
  IconContentBlockT,
  ImageContentBlockT,
  LinkContentBlockT,
  SubcontentBlockT,
  TextContentBlockT,
} from 'types'
import { DividerBlock, DocumentBlock, ImageBlock, LinkBlock, SubcontentBlock, TextBlock } from './Blocks'
import IconBlock from './Blocks/IconBlock/IconBlock'

interface IBlockProps {
  block: CmsContentBlocksT
}

// eslint-disable-next-line complexity
const renderBlock = (block: CmsContentBlocksT) => {
  switch (block.type) {
    case BLOCK_TYPE.divider:
      return <DividerBlock />

    case BLOCK_TYPE.document:
      if (!block.data) return <></>
      return <DocumentBlock data={block.data as DocumentContentBlockT} />

    case BLOCK_TYPE.image:
      if (!block.data) return <></>
      return <ImageBlock data={block.data as ImageContentBlockT} />

    case BLOCK_TYPE.text:
      if (!block.data) return <></>
      return <TextBlock data={block.data as TextContentBlockT} />

    case BLOCK_TYPE.link:
      if (!block.data) return <></>
      return <LinkBlock data={block.data as LinkContentBlockT} />

    case BLOCK_TYPE.icon:
      if (!block.data) return <></>
      return <IconBlock data={block.data as IconContentBlockT} />

    case BLOCK_TYPE.subcontent:
      if (!block.data) return <></>
      return <SubcontentBlock data={block.data as SubcontentBlockT} />

    default:
      return <></>
  }
}

const Block: React.FC<IBlockProps> = ({ block }) => (
  <Box sx={JSON.parse(block.blockCss ?? '{}')}>{renderBlock(block)}</Box>
)

export default Block
